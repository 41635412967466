var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "jobAlert", style: _vm.stylesContainer },
    [
      _c("green-container", [
        _c("div", { staticClass: "jobAlert__inner" }, [
          _c(
            "div",
            { staticClass: "jobAlert__col" },
            [
              _c("job-mail-teaser", {
                staticClass: "jobAlert__teaser",
                attrs: {
                  headline: _vm.headline,
                  subtitle: _vm.subtitle,
                  text: _vm.text,
                  cta: _vm.cta,
                },
              }),
            ],
            1
          ),
          _vm._v(" "),
          _vm.image
            ? _c("div", { staticClass: "jobAlert__col", class: _vm.classes }, [
                _c(
                  "div",
                  {
                    ref: "image",
                    staticClass: "jobAlert__image",
                    style: _vm.stylesImage,
                  },
                  [_c("image-tag", { attrs: { image: _vm.image } })],
                  1
                ),
              ])
            : _vm._e(),
        ]),
      ]),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }