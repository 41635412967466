



































import {isNull, PlainObject} from '@labor-digital/helferlein';
import {HelferleinEventList} from '@labor-digital/helferlein/lib/Events/HelferleinEventList';
import {ContentElementContext} from '@labor-digital/typo3-vue-framework/lib/Core/Context/ContentElementContext';
import {AppStoreKeys} from '../../../AppStoreKeys';
import JobMailTeaser from '../../../Component/Job/JobMailTeaser/JobMailTeaser.vue';
import GreenContainer from '../../../Component/Layout/GreenContainer/GreenContainer.vue';
import Btn from '../../../Component/Misc/Btn/Btn.vue';
import ImageTag from '../../../Component/Misc/Image/ImageTag/ImageTag.vue';
import ComponentProxyAwareMixin from '../../../Mixin/ComponentProxyAwareMixin';

export default {
    name: 'JobAlert',
    components: {ImageTag, JobMailTeaser, GreenContainer, Btn},
    mixins: [ComponentProxyAwareMixin],
    props: {
        context: null as ContentElementContext
    },
    data()
    {
        return {
            isBreakpointMd: false,
            imageHeight: 0
        };
    },
    computed: {
        headline(): string
        {
            return this.context.data.get('headline');
        },
        subtitle(): string
        {
            return this.context.data.get('subtitle');
        },
        text(): string
        {
            return this.context.data.get('text');
        },
        cta(): string
        {
            return this.context.data.get('cta');
        },
        image(): PlainObject
        {
            const img = this.context.data.get('image', null);
            return isNull(img) ? null : img[0];
        },
        internalHeadline(): string
        {
            return this.headline ?? this.$t('job.mailteaser.headline');
        },
        internalSubtitle(): string
        {
            return this.subtitle ?? this.$t('job.mailteaser.subtitle');
        },
        internalText(): string
        {
            return this.text ?? this.$t('job.mailteaser.text');
        },
        internalCta(): string
        {
            return this.cta ?? this.$t('job.mailteaser.cta');
        },
        breakpoint()
        {
            return this.$root.appContext.store.get(AppStoreKeys.BREAKPOINT);
        },
        stylesContainer()
        {
            return {
                paddingTop: this.isBreakpointMd ? this.imageHeight + 'px' : null
            };
        },
        stylesImage()
        {
            return {
                marginTop: this.isBreakpointMd ? '-' + this.imageHeight + 'px' : null
            };
        },
        classes()
        {
            return {
                'jobMailTeaser__col--order': this.isBreakpointMd && this.image
            };
        }
    },

    methods: {
        onWindowResize()
        {
            if (this.image) {
                this.isBreakpointMd = this.breakpoint.is('<=', 'md');
                this.imageHeight = this.$refs.image.clientHeight - 28;
            }
        }
    },
    mounted()
    {
        this.onWindowResize();

        this.proxy.bind(
            this.$root.appContext.eventEmitter,
            HelferleinEventList.EVENT_RESIZE_THROTTLED,
            this.onWindowResize);
    }
};
